import { FC, forwardRef, ReactNode } from 'react'
import styled from 'styled-components'
import {
	alignItems,
	AlignItemsProps,
	border,
	BorderProps,
	borderRadius,
	BorderRadiusProps,
	color,
	ColorProps,
	display,
	DisplayProps,
	margin,
	MarginProps,
	padding,
	PaddingProps,
	typography,
	TypographyProps,
} from 'styled-system'

export interface LinkProps
	extends AlignItemsProps,
		BorderProps,
		ColorProps,
		DisplayProps,
		TypographyProps,
		PaddingProps,
		MarginProps,
		BorderRadiusProps {
	href?: string
	textDecoration?: string
	hoverTextDecoration?: string
	textTransform?: string
	hoverColor?: string
	hoverBackgroundColor?: string
}

export const Link = styled.a<LinkProps>`
	${color};

	letter-spacing: 0;
	${typography};

	${display};
	${alignItems};

	${padding};
	${margin};
	${border};
	${borderRadius};

	text-transform: ${({ textTransform }) => textTransform};

	text-decoration: underline;
	text-decoration: ${({ textDecoration }) => textDecoration};

	text-align: right;

	transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);

	&:hover {
		color: ${({ hoverColor, theme }) => (theme.colors[hoverColor] ? theme.colors[hoverColor] : hoverColor)};
		background-color: ${({ hoverBackgroundColor, theme }) =>
			theme.colors[hoverBackgroundColor] ? theme.colors[hoverBackgroundColor] : hoverBackgroundColor};
		text-decoration: ${({ hoverTextDecoration }) => hoverTextDecoration};
	}
`

Link.defaultProps = {
	fontFamily: 'link',
}

export const NavLink = styled.a`
	color: rgba(255, 255, 255, 0.7);
	display: flex;
	align-items: center;
	font-family: ${({ theme }) => theme.fonts.link};
	font-size: 11px;
	letter-spacing: 2px;
	text-transform: uppercase;
	text-decoration: none;
	padding: 10px;
	transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);

	:hover {
		color: white;
	}
`;