import { FC } from 'react';
import Select, { StylesConfig, Props, components } from 'react-select';
import { useTheme } from 'styled-components';
import { P } from '../Text';

interface CustomProps extends Props {
	isImageSelect?: boolean;
	noImageRadius?: boolean;
	isDescriptionSelect?: boolean;
	yMargin?: number;
	bgColor?: string;
	padding?: string;
	isBorder?: boolean;
	minWidth?: string;
}

export const CustomSelect: FC<CustomProps> = (props) => {
	const theme = useTheme();

	const { SingleValue, Option } = components;

	const ImageSingleValue = (imgProps) => (
		<SingleValue {...imgProps}>
			{imgProps.data.image && (
				<img
					src={imgProps.data.image}
					style={{ borderRadius: props.noImageRadius ? '0px' : '50%', marginRight: '7.5px' }}
					height={'25px'}
					width={'25px'}
				/>
			)}
			{imgProps.data.label}
		</SingleValue>
	);

	const ImageOption = (imgProps) => (
		<Option {...imgProps}>
			{imgProps.data.image && (
				<img
					src={imgProps.data.image}
					style={{ borderRadius: props.noImageRadius ? '0px' : '50%', marginRight: '7.5px' }}
					height={'25px'}
					width={'25px'}
				/>
			)}
			{imgProps.data.label}
		</Option>
	);

	const DescriptionOption = (optionsProps: any) => (
		<Option {...optionsProps}>
			{optionsProps.data.label}
			{optionsProps.data.description && (
				<P color={theme.current === 'dark' && 'textSecondary'} opacity={theme.current === 'light' && 0.75} marginTop={'2px'} textAlign={'left'} fontSize={15}>
					{optionsProps.data.description}
				</P>
			)}
		</Option>
	)

	const customStyles: StylesConfig = {
		menuPortal: (provided) => ({
			...provided,
			zIndex: 100,
			width: props.isDescriptionSelect ? 300 : provided.width,
		}),
		menu: (provided) => ({
			...provided,
			backgroundColor: theme.colors.bgPrimary,
			boxShadow: '0px 7px 15px 3px rgba(0,0,0,0.25);',
			padding: '0px 5px',
		}),
		option: (provided, state) => ({
			...provided,
			padding: '10px',
			borderRadius: 2,
			margin: '5px 0px',
			backgroundColor: state.isSelected ? theme.current === 'dark' ? theme.colors.primaryBlue + 19 : theme.colors.primaryBlue : theme.colors.bgPrimary,
			cursor: 'pointer',
			display: 'flex',
			alignItems: props.isDescriptionSelect ? 'flex-start' : 'center',
			flexDirection: props.isDescriptionSelect ? 'column' : 'row',
			'&:hover': {
				backgroundColor: !state.isSelected && theme.colors.bgQuaternary,
			},
		}),
		control: () => ({
			backgroundColor: props.bgColor ? theme.colors[props.bgColor] : theme.colors.bgQuaternary,
			borderRadius: 2,
			width: '100%',
			display: 'flex',
			padding: !props.isImageSelect ? props.padding || '0px 2.5px' : '2.5px',
			transition: '0.5s',
			cursor: 'pointer',
			margin: props.yMargin ? `${props.yMargin}px 0px` : '0px',
			border: props.isBorder ? `1px solid ${theme.colors.accentSecondary}` : 'none',
			minWidth: props.minWidth,
		}),
		singleValue: (provided) => ({
			...provided,
			color: theme.colors.textPrimary,
			display: 'flex',
			alignItems: 'center',
		}),
		dropdownIndicator: (base, state) => ({
			...base,
			color: 'gray',
			transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
			transition: '0.3s',
		}),
		indicatorSeparator: () => ({
			display: 'none',
		}),
		input: (base) => ({
			...base,
			color: theme.colors.textSecondary,
		}),
	};

	return (
		<Select
			{...props}
			styles={customStyles}
			menuPlacement={'auto'}
			menuPosition={'fixed'}
			components={props.isImageSelect ? { SingleValue: ImageSingleValue, Option: ImageOption } : { Option: DescriptionOption }}
			maxMenuHeight={350}
		/>
	);
};