import { CookieService } from '@services';
import io, { Socket } from 'socket.io-client';

class SocketService {
    public socket: typeof Socket;

    public connect(namespace: string) {
        const token = CookieService.getAccessToken();

        if (!token) return;

        this.socket = io(`${process.env.NEXT_PUBLIC_API_URL}/${namespace}`, {
            query: {
                token,
            },
        });

        this.socket.on('connect', () => {
            console.log('Socket Connected');
        });

        this.socket.on('disconnect', () => {
            console.log('Socket Disconnected');
        });
    }

    public disconnect() {
        if (this.socket?.connected) {
            this.socket.disconnect();
        }
    }
}

export default new SocketService();