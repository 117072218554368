import styled, { css } from 'styled-components';

export const CharCounterContainer = styled.div<{ danger?: boolean }>`
	color: ${({ theme }) => theme.colors.textSecondary};
	font-size: 14px;
	display: flex;
	align-items: center;
	margin-right: auto;
	transition: color 0.25s;

	${({ danger, theme }) => danger && css`
		color: ${theme.colors.primaryRed};
	`}
`;