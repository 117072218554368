import sanitize from 'sanitize-html'
import { Rule } from './types'

const sanitizeString = (string: string) => {
	return sanitize(string, {
		allowedTags: ['b', 'i', 'em', 'ul', 'br', 'blockquote'],
	})
}

export const sanitizeRules = (rules: Rule[]) => {
	return rules.map((rule) => {
		return {
			...rule,
			description: sanitizeString(rule.description),
		}
	})
}
