import styled, { keyframes } from 'styled-components'
import { margin, MarginProps } from 'styled-system'

const spinner = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`

interface SpinnerProps extends MarginProps {
	size?: number;
};

export const Spinner = styled.div<SpinnerProps>`
	width: ${({ size }) => size ? size + 'px' : '50px'};
	height: ${({ size }) => size ? size + 'px' : '50px'};;

	font-size: 10px;
	text-indent: -12345px;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;

	${margin};

	${({ theme }) => `
		border-top: 1px solid ${theme.current === 'light' ? 'rgba(0, 0, 0, 0.08)' : 'rgba(255, 255, 255, 0.08)'};
		border-right: 1px solid ${theme.current === 'light' ? 'rgba(0, 0, 0, 0.08)' : 'rgba(255, 255, 255, 0.08)'};
		border-bottom: 1px solid ${theme.current === 'light' ? 'rgba(0, 0, 0, 0.08)' : 'rgba(255, 255, 255, 0.08)'};
		border-left: 1px solid ${theme.current === 'light' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)'};
	`}

	animation: ${spinner} 700ms infinite linear;
`
