import { IndividualStatisticsData } from './types'

export const STAT_MAPPING = {
	'farming_plant_cloth_harvested': 'Cloth Harvested',
	'farming_plant_corn_harvested': 'Corn Harvested',
	'farming_plant_potato_harvested': 'Potato Harvested',
	'farming_plant_pumpkin_harvested': 'Pumpkin Harvested',
	'farming_plant_mushroom_harvested': 'Mushroom Harvested',
	'farming_plant_berry_blue_harvested': 'Blue Berry Harvested',
	'farming_plant_berry_green_harvested': 'Green Berry Harvested',
	'farming_plant_berry_red_harvested': 'Red Berry Harvested',
	'farming_plant_berry_white_harvested': 'White Berry Harvested',
	'farming_plant_berry_yellow_harvested': 'Yellow Berry Harvested',
	'farming_plant_berry_black_harvested': 'Black Berry Harvested',
	'farming_resource_stone_harvested': 'Stones Harvested',
	'farming_resource_sulfur_harvested': 'Sulfur Ore Harvested',
	'farming_resource_wood_harvested': 'Wood Harvested',
	'farming_resource_hqm_harvested': 'HQ Metal Ore Harvested',
	'farming_resource_metal_harvested': 'Metal Ore Harvested',
	'pve_animal_bear_kills': 'Bear Kills',
	'pve_animal_boar_kills': 'Boar Kills',
	'pve_animal_stag_kills': 'Stag Kills',
	'pve_animal_wolf_kills': 'Wolf Kills',
	'pve_animal_shark_kills': 'Shark Kills',
	'event_attackhelicopter_kills': 'Attack Heli Kills',
	'event_bradleyapc_kills': 'Bradley Kills',
	'fishing_caught_fish': 'Fish Caught',
	'farming_animal_fat_harvested': 'Animal Fat Harvested',
	'farming_animal_leather_harvested': 'Leather Harvested',
	'farming_animal_bone_harvested': 'Bone Fragments Harvested',
	'pvp_player_kills_total': 'Kills',
	'pvp_player_kills_mounted_helicopter': 'Heli Kills',
	'pvp_player_kills_competitive': 'Competitive Kills',
	'pvp_player_kills_diving': 'Diving Kills',
	'pvp_player_longestkill': 'Longest Kill',
	'pvp_player_deaths_total': 'Deaths',
	'pvp_player_deaths_suicides': 'Suicides',
	'pvp_player_headshot': 'Headshot',
	'pvp_player_wounds_total': 'Wounds',
	'pvp_player_wounds': 'Wounds',
	'pvp_player_wounds_self': 'Self-Wounds',
	'pvp_player_kills_oilrig': 'Oilrig Kills',
	'pvp_player_deaths_oilrig': 'Oilrig Deaths',
	'weapon_bullet_fired_total': 'Bullets Fired',
	'weapon_bullet_hit_player': 'Bullets Hit',
	'weapon_rocket_launched_basic': 'Rockets Launched',
	'weapon_rocket_launched_hv': 'HV Rockets Launched',
	'item_thrown_c4': 'C4 Thrown',
	'item_thrown_satchel': 'Satchels Thrown',
	'fishing_gutting_total': 'Fish Gutted',
	'fishing_gutting_item_card_blue': 'Fish Gutting',
	'loot_crate_total': 'Loot Crates Looted',
	'loot_barrels_total': 'Loot Barrels Broken',
	'loot_freed_crate': 'Loot Crates Freed',
	'item_dropped_total': 'Items Dropped',
	'player_time_swimming': 'Time Swimming',
	'player_time_played': 'Time Played',
	'player_mission_started': 'Missions Started',
	'player_mission_completed': 'Missions Completed',
	'gambling_wheel_scrap_wagered': 'Scrap Wagered (Wheel)',
	'gambling_wheel_scrap_won': 'Scrap Won (Wheel)',
	'gambling_slots_scrap_wagered': 'Scrap Wagered (Slots)',
	'gambling_slots_scrap_won': 'Scrap Won (Slots)',
	'kdr': 'KDR',
	'accuracy': 'Accuracy',
	'item_scrap_gathered_barrel': 'Barrel Scrap',
	'item_scrap_gathered_crate': 'Crate Scrap',
	'item_scrap_gathered_recycler': 'Recycler Scrap',
	'pvp_player_kills_mounted_submarine': 'Sub Kills',
	'entity_submarine_kills_submarine': 'Subs Destroyed',
	'entity_boat_kills_submarine': 'Boats Destroyed',
}

export const StatTipMapping = {
	'pvp_player_kills_diving': 'Kills made while diving',
	'pvp_player_longestkill': 'Longest kill in meters',
	'pvp_player_kills_oilrig': 'Kills made on Oilrig',
	'pvp_player_deaths_oilrig': 'Deaths on Oilrig',
	'pvp_player_kills_mounted_helicopter': 'Kills made while on minicopter',
	'pvp_player_kills_competitive': 'Kills against non-naked players',
	'pvp_player_wounds_self': 'Wounds inflicted on self',
	'event_attackhelicopter_kills': 'Kills against PVE Attack Helicopter',
	'event_bradleyapc_kills': 'Kills against PVE Bradley tank',
	'item_scrap_gathered_barrel': 'Scrap gathered from barrels',
	'item_scrap_gathered_crate': 'Scrap gathers from crates',
	'item_scrap_gathered_recycler': 'Scrap gathered from recyclers',
	'gambling_wheel_scrap_wagered': 'Scrap wagered at the Bandit Camp wheel',
	'gambling_wheel_scrap_won': 'Scrap won at the Bandit Camp wheel',
	'gambling_slots_scrap_wagered': 'Scrap wagered at the Bandit Camp slots',
	'gambling_slots_scrap_won': 'Scrap won at the Bandit Camp slots',
	'pvp_player_kills_mounted_submarine': 'Kills made while mounted in submarine',
	'entity_submarine_kills_submarine': 'Submarines destroyed',
	'entity_boat_kills_submarine': 'Boats destroyed',
}

export const compareStatData = (
	firstData: IndividualStatisticsData[0],
	secondData: IndividualStatisticsData[0]
): { total: 'FIRST' | 'SECOND' | 'TIE'; data: { [field: string]: 'FIRST' | 'SECOND' | 'TIE' } } => {
	const total =
		firstData?.total > secondData?.total ? 'FIRST' : firstData?.total === secondData?.total ? 'TIE' : 'SECOND'

	const data = Object.entries(firstData?.data || {}).reduce(
		(acc, [field, value]) => ({
			...acc,
			[field]:
				value > secondData?.data?.[field]
					? 'FIRST'
					: value === secondData?.data?.[field]
					? 'TIE'
					: 'SECOND',
		}),
		{}
	)

	return { total, data }
}
