import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Colors } from '@app-types/styled';

export const AnnouncementWrapper = styled(motion.div)`
	background-color: ${({ theme }) => theme.colors.bgPrimary};
	border-radius: 5px;
	box-shadow: 5px 5px 5px 2px rgba(0,0,0,0.29);
	position: fixed;
	left: 15px;
	bottom: 15px;
	width: 350px;
	display: flex;
	flex-direction: column;
	z-index: 999;
`;

export const AnnouncementContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
`;

export const AnnouncementType = styled.div`
	color: ${({ theme }) => theme.colors.primaryGreen};
	font-family: ${({ theme }) => theme.fonts.heading};
	font-size: 14px;
	text-transform: uppercase;
	margin-bottom: 5px;
`;

export const AnnouncementHeader = styled.div`
	font-weight: 700;
	font-size: 22px;
`;

export const AnnouncementBody = styled.div`
	color: ${({ theme }) => theme.colors.textSecondary};
	margin-top: 5px;
`;

export const AnnouncementButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 25px;
`;

interface AnnouncementButtonProps {
	bgColor: keyof Colors;
	hoverColor: keyof Colors;
}

export const AnnouncementButton = styled.button<AnnouncementButtonProps>`
	background-color: ${({ theme, bgColor }) => theme.colors[bgColor]};
	border-radius: 2px;
	color: white;
	font-family: ${({ theme }) => theme.fonts.link};
	font-size: 12px;
	letter-spacing: 2px;
	text-transform: uppercase;
	padding: 8px 14px;
	transition: 0.25s all;
	margin: 0 5px;
	flex-grow: 1;
	flex-basis: 0;

	:hover {
		background-color: ${({ theme, hoverColor }) => theme.colors[hoverColor]};
	}

	:first-child {
		margin-left: 0px;
	}

	:last-child {
		margin-right: 0px;
	}
`;

export const AnnouncementBanner = styled.img`
	width: 100%;
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
`;