import { ChangeEvent, Dispatch, FC, Fragment, SetStateAction } from 'react'
import { motion } from 'framer-motion'

import styled, { css } from 'styled-components'
import {
	border,
	borderColor,
	BorderColorProps,
	BorderProps,
	borderRadius,
	BorderRadiusProps,
	color,
	ColorProps,
	fontFamily,
	FontFamilyProps,
	fontSize,
	FontSizeProps,
	FontWeightProps,
	height,
	HeightProps,
	letterSpacing,
	LetterSpacingProps,
	margin,
	MarginProps,
	maxWidth,
	MaxWidthProps,
	padding,
	PaddingProps,
	textAlign,
	TextAlignProps,
	width,
	WidthProps,
} from 'styled-system'

import { P } from '../Text'

export interface TextInputProps
	extends FontFamilyProps,
		FontWeightProps,
		FontSizeProps,
		ColorProps,
		BorderProps,
		BorderRadiusProps,
		BorderColorProps,
		TextAlignProps,
		LetterSpacingProps,
		WidthProps,
		MaxWidthProps,
		PaddingProps,
		HeightProps,
		MarginProps {
	placeholder?: string
	placeholderColor?: string
	// TODO change this to a function
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

/* TODO: Later have a custom border focus */
export const TextInput = styled.input<TextInputProps>`
	${fontFamily};
	${fontSize};
	${color};
	${width};
	${maxWidth};
	${borderRadius};
	${margin}

	${padding};

	${height};

	${textAlign};
	${letterSpacing};

	&[type='number'] {
		appearance: none;
		-moz-appearance: textfield;
	}

	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	${border};
	${borderColor};
`

TextInput.defaultProps = {
	fontFamily: 'body',
	fontSize: 14,
	width: '100%',
}

// Switch
const SwitchSquare = styled.div`
	position: absolute;
	width: 18px;
	height: 18px;
	background-color: ${({ theme }) => theme.colors.bgPrimary};
	top: 3px;
	left: 3px;
	border-radius: 4px;
	transition: 0.3s;
`

interface SwitchWrapperProps {
	$on: boolean
}

const SwitchOnStyles = css`
	background-color: #1ab585;

	> ${SwitchSquare} {
		left: 24px;
	}
`

const SwitchWrapper = styled.div<SwitchWrapperProps>`
	background-color: ${({ theme }) => theme.colors.accentPrimary};
	border-radius: 5px;
	padding: 0 5px;
	display: flex;
	align-items: center;
	height: 24px;
	min-width: 45px;
	position: relative;
	cursor: pointer;

	i {
		font-size: 14px;
		color: white;
	}

	${({ $on }) => $on && SwitchOnStyles};
`

interface SwitchProps {
	on: boolean
	setOn: Dispatch<SetStateAction<boolean>>
}

export const Switch: FC<SwitchProps> = ({ on, setOn }) => {
	return (
		<SwitchWrapper $on={on} onClick={() => setOn((on) => !on)}>
			<i className={'fas fa-check'} />
			<SwitchSquare />
		</SwitchWrapper>
	)
}

interface RadioWrapperProps {
	selected: boolean;
	disabled?: boolean;
}

// Radio
const RadioWrapper = styled.div<RadioWrapperProps>`
	border: 1px solid ${({ theme }) => theme.colors.accentSecondary};
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	align-items: center;
	margin: 5px 0;
	padding: 12px;
	user-select: none;
	transition: 0.25s background-color, 0.25s border-color, 0.25s opacity;
	position: relative;

	${({ disabled }) => disabled && css`
		cursor: not-allowed;
		opacity: 0.5;

		* {
			cursor: not-allowed !important;
		}
	`}
`;

const RadioInput = styled.input`
	appearance: none;
	border: 1px solid ${({ theme }) => theme.colors.accentPrimary};
	border-radius: 50%;
	cursor: pointer;
	min-width: 20px;
	min-height: 20px;
	margin-right: 15px;
	transition: 0.3s;
	position: relative;
	transition: 0.25s background-color, 0.25s border-color;

	:after {
		background-color: ${({ theme }) => theme.colors.primaryBlue};
		border-radius: 50%;
		content: '';
		width: 12px;
		height: 12px;
		position: absolute;
		top: 50%;
		left: 50%;
  		transform: translate(-50%, -50%) scale(0);
		transition: 0.25s transform;
	}

	:checked {
		border-color: ${({ theme }) => theme.colors.primaryBlue};

		:after {
			transform: translate(-50%, -50%) scale(1);
		}
	}
`

export const RadioSelected = styled(motion.div)`
	background-color: ${({ theme }) => theme.colors.primaryBlue}1A;
	border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
	border-radius: inherit;
	margin: -12px;
	width: 100%;
	height: 100%;
	position: absolute;
`;

RadioInput.defaultProps = {
	type: 'radio',
}

const RadioLabel = styled.label<FontFamilyProps>`
	${fontFamily};
	font-size: 15px;
	cursor: pointer;
`

const RadioDescription = styled.div`
	color: ${({ theme }) => theme.colors.textSecondary};
	font-size: 14px;
`;

RadioLabel.defaultProps = {
	fontFamily: 'body',
}

interface RadioProps {
	id: string;
	topic: string;
	text: string;
	description: string;
	selected: boolean;
	setSelected: () => void;
	disabled?: boolean;
}

export const Radio: FC<RadioProps> = ({ id, topic, text, description, selected, setSelected, disabled }) => {
	return (
		<RadioWrapper selected={selected} disabled={disabled}>
			<RadioInput id={id} name={topic} checked={selected} disabled={disabled} onChange={setSelected} />
			<RadioLabel htmlFor={id}>
				{text}
				<RadioDescription>
					{description}
				</RadioDescription>
			</RadioLabel>
			{selected && (
				<RadioSelected
					layoutId={'radio-selected'}
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
				/>
			)}
		</RadioWrapper>
	)
}

// Image Input
const ImageInputImageStyles = css`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

interface ImageInputWrapperProps {
	image: File
}

const ImageInputWrapper = styled.label<ImageInputWrapperProps>`
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1.5px solid ${({ theme }) => theme.colors.accentPrimary};
	box-shadow: 0 0 0.5px 0.6px rgb(51, 51, 51);
	cursor: pointer;
	overflow: hidden;
	object-fit: contain;

	img {
		${({ image }) => image && ImageInputImageStyles}
	}
`

const ImageInputInput = styled.input`
	display: none;
`

ImageInputInput.defaultProps = {
	type: 'file',
	name: 'image',
	id: 'image',
	accept: 'image/*',
}

interface ImageInputProps {
	image: File
	setImage: Dispatch<SetStateAction<File>>
}

export const ImageInput: FC<ImageInputProps> = ({ image, setImage }) => {
	return (
		<Fragment>
			<label htmlFor={'edit-image'}>
				<ImageInputWrapper image={image} htmlFor={'edit-image'}>
					{image ? (
						<img src={URL.createObjectURL(image)} alt={'Clan Logo'} />
					) : (
						<P
							textAlign={'center'}
							fontSize={12}
							textTransform={'uppercase'}
							fontFamily={'heading'}
							userSelect={'none'}
						>
							Upload image
						</P>
					)}
				</ImageInputWrapper>
			</label>
			<ImageInputInput
				id={'edit-image'}
				name={'edit-image'}
				accept={'image/*'}
				onChange={(e) => setImage(e.target.files[0])}
			/>
		</Fragment>
	)
}
