import styled from 'styled-components'
import {
	borderRadius,
	BorderRadiusProps,
	height,
	HeightProps,
	margin,
	MarginProps,
	width,
	WidthProps,
} from 'styled-system'

interface SkeletonProps extends WidthProps, HeightProps, MarginProps, BorderRadiusProps {
	isDark?: boolean;
}

export const Skeleton = styled.div<SkeletonProps>`
	${width};
	${height};
	${margin};
	${borderRadius};

	background-color: ${({ theme, isDark }) => isDark ? theme.colors.bgTertiary : theme.colors.bgSecondary};
	position: relative;
	overflow: hidden;
	opacity: 0.5;

	:after {
		animation: loading 1s infinite;
		background: linear-gradient(90deg, transparent, #61595933, transparent);
		content: '';
		display: block;
		height: 100%;
		position: absolute;
		top: 0;
		transform: translateX(-100px);
		transition: 1s all;
		width: 100%;
	}

	@keyframes loading {
		100% {
			transform: translateX(100%);
		}
	}
`
