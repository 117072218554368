import styled, { keyframes } from 'styled-components'

const loader = keyframes`
    75% {
        opacity: 1;
    }
    99.999% {
        display: none;
        opacity: 0;
        top: 0px;
        height: 100%;
    }
    100% {
        height: 0;
        top: -5000px;
        opacity: 0;
    }
`

export const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.colors.body};
	z-index: 999999;
	animation: ${loader} 500ms linear;
	animation-fill-mode: forwards;

	display: flex;
	justify-content: center;
	align-items: center;
`
