import styled from 'styled-components'
import { fontFamily, FontFamilyProps } from 'styled-system'

export const LinkWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;

	margin-bottom: 5px;
`

export const Link = styled.input<FontFamilyProps>`
	background-color: ${({ theme }) => theme.colors.bgTertiary};
	border: 1px solid ${({ theme }) => theme.colors.accentPrimary};
	border-radius: 5px 0px 0px 5px;
	padding: 0 10px;
	outline: 0;
	height: 30px;
	width: 100%;
	max-width: 285px;
	font-size: 14px;
	${fontFamily};
`

Link.defaultProps = {
	fontFamily: 'body',
}

export const CopyButton = styled.span<FontFamilyProps>`
	background-color: #0b0b0b;
	border: 1px solid #0b0b0b;
	border-radius: 0px 5px 5px 0px;
	border-left: none;
	cursor: pointer;
	color: white;
	outline: 0;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 0.25s all;
	width: 100px;
	letter-spacing: 2px;
	font-family: ${({ theme }) => theme.fonts.heading};

	&:hover {
		letter-spacing: 4px;
	}
`