import { useEffect } from 'react'

declare global {
	interface Window {
		Weglot: {
			initialize: ({ api_key }) => void
		}
	}
}

/**
 * Initializes the Weglot instance (i.e. adds translation options to page)
 */
export function useWeglot() {
	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://cdn.weglot.com/weglot.min.js';
		script.async = true;
		document.body.appendChild(script);

		const initializeWeglot = () => {
			window.Weglot.initialize({
				api_key: process.env.NEXT_PUBLIC_WEGLOT_API_KEY,
			});
		}

		script.addEventListener('load', initializeWeglot);

		return () => {
			script.removeEventListener('load', initializeWeglot);
			document.body.removeChild(script);
		}
	}, [])
}
