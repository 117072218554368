import styled from 'styled-components'
import { margin, MarginProps } from 'styled-system'

export interface DividerProps extends MarginProps {}

export const Divider = styled.hr<DividerProps>`
	border: 0;
	height: 1px;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.accentPrimary};
	${margin};
`

Divider.defaultProps = {
	marginTop: 10,
	marginBottom: 10,
}

export const VerticalDivider = styled.hr`
	width: 1px;
	height: 100%;
	border: 0;
	background-color: ${({ theme }) => theme.colors.accentPrimary};
	margin: 0 20px;
`
