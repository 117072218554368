import { useQuery } from 'react-query'
import { ApiService } from '@services'

export function useGetServer(id: string, { onSuccess }) {
	return useQuery(['servers', id], async () => await ApiService.getServer(id), {
		onSuccess,
		refetchOnWindowFocus: false,
	})
}

export function useGetServerInfo({ initialData, onSuccess }) {
	return useQuery('serverInfo', async () => await ApiService.getServerInfo, { initialData, onSuccess })
}
