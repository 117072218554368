import styled, { css } from 'styled-components'
import {
	alignItems,
	AlignItemsProps,
	alignSelf,
	AlignSelfProps,
	color,
	ColorProps,
	display,
	DisplayProps,
	flexbox,
	FlexboxProps,
	FontFamilyProps,
	FontSizeProps,
	FontWeightProps,
	justifySelf,
	JustifySelfProps,
	letterSpacing,
	LineHeightProps,
	margin,
	MarginProps,
	maxWidth,
	MaxWidthProps,
	opacity,
	OpacityProps,
	overflow,
	OverflowProps,
	typography,
	TypographyProps,
	width,
	WidthProps,
} from 'styled-system'

export interface HeadingProps
	extends TypographyProps,
		AlignSelfProps,
		JustifySelfProps,
		MaxWidthProps,
		MarginProps,
		OverflowProps,
		WidthProps {
	textTransform?: string
	textOverflow?: string
	whitespace?: string
}

export interface ParagraphProps
	extends AlignItemsProps,
		TypographyProps,
		DisplayProps,
		WidthProps,
		MaxWidthProps,
		MarginProps,
		OverflowProps,
		FlexboxProps,
		OpacityProps {
	textOverflow?: string
	textTransform?: string
	userSelect?: string
	whitespace?: string
	hoverColor?: string
	dataToggle?: string
}

export interface SpanProps extends ParagraphProps {}

export interface ListItemProps extends ParagraphProps {}

export interface PillTextProps
	extends ColorProps,
		FontFamilyProps,
		FontSizeProps,
		FontWeightProps,
		LineHeightProps,
		WidthProps {
	visibility?: string
}

export interface LabelProps extends FontFamilyProps, FontSizeProps, FontWeightProps, LineHeightProps {}

const headingStyles = css`
	${color};
	${typography};
	${alignSelf}
	${justifySelf};

	${maxWidth};

	margin: 0;

	${margin};
	${overflow};

	${width};
`

// Heading
const H6 = styled.h6<HeadingProps>`
	${headingStyles};
	text-transform: ${({ textTransform }) => textTransform};
`

H6.defaultProps = {
	fontFamily: 'heading',
}

const H5 = styled.h5<HeadingProps>`
	${headingStyles};
	text-transform: ${({ textTransform }) => textTransform};
`

H5.defaultProps = {
	fontFamily: 'heading',
}

const H4 = styled.h4<HeadingProps>`
	${headingStyles};
	text-transform: ${({ textTransform }) => textTransform};
`

H4.defaultProps = {
	fontFamily: 'heading',
}

const H3 = styled.h3<HeadingProps>`
	${headingStyles};
	text-transform: ${({ textTransform }) => textTransform};
`

H3.defaultProps = {
	fontFamily: 'heading',
}

const H2 = styled.h2<HeadingProps>`
	${headingStyles};
	text-transform: ${({ textTransform }) => textTransform};
	text-overflow: ${({ textOverflow }) => textOverflow};
	white-space: ${({ whitespace }) => whitespace};
`

H2.defaultProps = {
	fontFamily: 'heading',
}

const H1 = styled.h1<HeadingProps>`
	${headingStyles};
	text-transform: ${({ textTransform }) => textTransform};
`

H1.defaultProps = {
	fontFamily: 'heading',
}

export const Heading = {
	H1,
	H2,
	H3,
	H4,
	H5,
	H6,
}

// Paragraph
export const P = styled.p<ParagraphProps>`
	${color};
	${typography};
	${flexbox};
	${width};
	${maxWidth};
	margin: 0;
	${margin};
	${overflow};
	${display};
	${opacity};

	${letterSpacing};
	text-transform: ${({ textTransform }) => textTransform};
	text-overflow: ${({ textOverflow }) => textOverflow};
	user-select: ${({ userSelect }) => userSelect};
	white-space: ${({ whitespace }) => whitespace};

	transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);

	:hover {
		color: ${({ hoverColor }) => hoverColor};
	}
`

P.defaultProps = {
	fontFamily: 'body',
}

// Span
export const Span = styled.span<SpanProps>`
	${color};

	line-height: 1;

	${typography};

	${alignItems};
	${maxWidth};
	margin: 0;
	${margin};
	${overflow};
	${display};
	${width};

	text-transform: ${({ textTransform }) => textTransform};
	text-overflow: ${({ textOverflow }) => textOverflow};
	white-space: ${({ whitespace }) => whitespace};
`

Span.defaultProps = {
	fontFamily: 'body',
}

// List Item
export const ListItem = styled.li<ListItemProps>`
	${color};
	line-height: 1;
	${typography};
	${alignItems};
	${maxWidth};
	margin: 0;
	${margin};
	${overflow};
	${display};

	${letterSpacing};
	text-transform: ${({ textTransform }) => textTransform};
	text-overflow: ${({ textOverflow }) => textOverflow};
	white-space: ${({ whitespace }) => whitespace};
`

ListItem.defaultProps = {
	fontFamily: 'body',
}
