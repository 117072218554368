import styled from 'styled-components';

export const ToastContainer = styled.div`
    display: flex;
`

export const ToastLine = styled.hr`
    background-color: ${({ theme }) => theme.colors.primaryRed};
    border: 0;
    border-radius: 4px;
    height: 100%;
    margin: 0px 12px 0px 0px;
    width: 3px;
`

export const ToastLink = styled.a`
    color: ${({ theme }) => theme.colors.primaryBlue};
    text-decoration: none;

    :hover { 
        text-decoration: underline;
    }
`

export const ToastTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;