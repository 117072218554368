import styled from 'styled-components'

export const Wrapper = styled.div``

interface SelectedProp {
	selected: boolean
	textHeight?: number
}

export const TitleWrapper = styled.div<SelectedProp>`
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-bottom: 10px;
	padding: 14px 20px;
	border: 1px solid ${({ theme }) => theme.colors.accentSecondary};
	background-color: ${({ theme }) => theme.colors.bgPrimary};

	cursor: pointer;

	transition: all 0.27s cubic-bezier(0, 0, 0.58, 1);

	> :last-child {
		transition: 0.25s all;
	}

	${({ selected }) =>
		selected &&
		`
		> :last-child {
			transform: rotate(180deg);
		}
	`}
`

export const ContentWrapper = styled.div<SelectedProp>`
	transition: all 0.25s ease-in-out;
	height: ${({ selected, textHeight }) => (selected ? textHeight : 0)}px;
	overflow: hidden;

	padding: 10px 20px 0;
	margin-bottom: 10px;
`
