import styled from 'styled-components';

export const SelectorWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.bgQuaternary};
	width: fit-content;
	margin-left: auto;
	border-radius: 2px;
	user-select: none;
	overflow: hidden;
`;

interface SelectorOptionProps {
	selected: boolean;
	buttonType: boolean | null;
}

export const SelectorOption = styled.button<SelectorOptionProps>`
	background-color: ${({ selected, theme, buttonType }) => selected ? (
		buttonType === null ? (
			theme.colors.bgDark
		) : (
			buttonType === true ? theme.colors.secondaryGreen : theme.colors.primaryRed
		)
	) : ''};
	
	color: ${({ selected, theme }) => (selected ? 'white' : theme.colors.textTertiary)};

	transition: 0.25s all;

	height: 100%;
	padding: 0 10px;

	:hover {
		${({ selected, theme }) => !selected && `background-color: ${theme.colors.bgTertiary};`}
	}

	:first-child {
		border-radius: inherit 0px 0px inherit;
	}

	:last-child {
		border-radius: 0px inherit inherit 0px;
	}
`;
