import { motion } from 'framer-motion';
import styled, { css, FlattenInterpolation, FlattenSimpleInterpolation } from 'styled-components';

export interface ModalProps {
	isOpen: boolean
}

export const ModalWrapper = styled.aside<ModalProps>`
    display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);

	width: 100%;
	height: 100%;
	position: fixed;

	top: 0;
	left: 0;

	padding: 20px 0;

	overflow-y: scroll;
	overflow-x: hidden;

	@media (min-width: 576px) {
		overflow-y: auto;
		padding-bottom: 0;
	}

    transition: 0.25s all;

    ${({ isOpen }) => isOpen ? css`
        opacity: 1;
        z-index: 9999;
    ` : css`
        opacity: 0;
        z-index: -1;
    `}
`;

interface ModalBoxProps {
	customStyles?: FlattenSimpleInterpolation | FlattenInterpolation<any>;
}

export const ModalBox = styled(motion.div)<ModalBoxProps>`
    background-color: ${({ theme }) => theme.colors.body};
    border: 1px solid ${({ theme }) => theme.colors.accentSecondary};
    border-radius: 8px;
    width: 550px;
	overflow: hidden;

    ${({ customStyles }) => customStyles && customStyles}

    @media (max-width: 725px) {
        min-width: unset;
        margin: 0px 15px;
    }
`;

export const ModalHeader = styled.h1`
	border-bottom: 1px solid ${({ theme }) => theme.colors.accentSecondary};
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
    font-size: 22px;
	font-weight: 400;
    margin: 0px;
	padding: 10px 20px;
    display: flex;
    align-items: center;
	justify-content: space-between;
	font-family: ${({ theme }) => theme.fonts.heading};
	text-transform: uppercase;
`;

export const ModalBody = styled.div`
	padding: 20px;
`;

export const ModalCloseButton = styled.button`
	color: ${({ theme }) => theme.colors.textSecondary};
	transition: 0.25s color;

	:hover {
		color: ${({ theme }) => theme.colors.textPrimary};
	}
`;

export const ModalFooter = styled.div`
	background-color: ${({ theme }) => theme.colors.bgPrimary};
	border-top: 1px solid ${({ theme }) => theme.colors.accentSecondary};
	border-bottom-left-radius: inherit;
	border-bottom-right-radius: inherit;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 7.5px 10px;
`;