import toast from 'react-hot-toast'
import { ToastContainer, ToastLink, ToastTitle } from './styles';
import { P, Span } from '../Text';
import Link from 'next/link';

export const errorToast = (message: string, id?: string, description?: string, statusCode?: number|string) => {
    toast.error(() => (
        <ToastContainer>
				<div>
					<ToastTitle>
                        <P color={'primaryRed'} fontWeight={700}>{message}</P>
                        {statusCode && <P color={'primaryOrange'} fontSize={12} textTransform={'uppercase'}>Status Code: {statusCode}</P>}
                    </ToastTitle>
					<P color={'textTertiary'} fontSize={14}>{description ? description : 'Please try again'}. If the issue persists, please <Link href={'https://discord.com/invite/BbfhEhT'} passHref><ToastLink rel={'noreferer'} target={'_blank'}>contact support</ToastLink></Link>.</P>
                </div>
        </ToastContainer>
    ), { 
        id,
    });
}

export const infoToast = (message: string, description: string, extraText?: string, id?: string,) => {
    toast.success(() => (
        <ToastContainer>
            <div>
                <P color={'textSecondary'} fontSize={14} fontFamily={'heading'} textTransform={'uppercase'}>{extraText}</P>
                <ToastTitle>
                    <P color={'primaryGreen'} fontWeight={700}>{message}</P>
                </ToastTitle>
                <P color={'textTertiary'} fontSize={14}>{description}</P>
            </div>
        </ToastContainer>
    ), {
        id,
    });
}