import { IdUserData, UnlinkRequest } from './types';

const unlinkableServices = [
	'discord',
	'twitter',
	'twitch',
];

export const unlinkRequestDisabled = (userData: IdUserData, unlinks: UnlinkRequest[]) => {
    if (userData) {
        return unlinkableServices
            .filter(s => userData[s] !== undefined)
            .every(s => unlinks.find(u => u.service === s)?.status === 0);
    } else {
        return false;
    }
}