import styled from 'styled-components'
import { color, ColorProps, fontSize, FontSizeProps, margin, MarginProps } from 'styled-system'

export interface IconProps extends ColorProps, FontSizeProps, MarginProps {
	className: string
}

export const Icon = styled.i<IconProps>`
	${color};
	${fontSize};
	${margin};
`

Icon.defaultProps = {
	color: 'primary',
	fontSize: 14,
}
