import { motion } from 'framer-motion';
import { useDebounce } from 'hooks';
import { FC } from 'react';
import { CharCounterContainer } from './styles';

interface CharCounterProps {
	string: string;
	limit: number;
	min?: number;
}

export const CharCounter: FC<CharCounterProps> = ({ string, limit, min = 0 }) => {
	const debouncedLength = useDebounce<number>(string.length, 150);

	let isMin = debouncedLength < min;

	return (
		<CharCounterContainer danger={limit - debouncedLength <= 25 || isMin}>
			{Array.from(Math.abs(isMin ? debouncedLength - min : limit - debouncedLength).toString()).map(Number).map((n, i) => (
				<motion.span
					initial={{ y: 15, opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					key={`${i}-${n}`}
				>
					{n}
				</motion.span>
			))}
			&nbsp;{isMin ? 'more characters required' : 'characters remaining'}
		</CharCounterContainer>
	);
}