import { FC } from 'react';
import NextLink from 'next/link';

import styled, { css } from 'styled-components';
import {
	alignSelf,
	AlignSelfProps,
	border,
	BorderProps,
	borderRadius,
	BorderRadiusProps,
	color,
	ColorProps,
	display,
	DisplayProps,
	fontFamily,
	FontFamilyProps,
	fontSize,
	FontSizeProps,
	fontWeight,
	FontWeightProps,
	height,
	HeightProps,
	justifySelf,
	JustifySelfProps,
	lineHeight,
	margin,
	MarginProps,
	maxWidth,
	MaxWidthProps,
	minWidth,
	MinWidthProps,
	padding,
	PaddingProps,
	width,
	WidthProps,
} from 'styled-system';
import { Img } from '../Image';
import { useRouter } from 'next/router';
import { Colors } from '@app-types/styled';
import { motion } from 'framer-motion';

export interface ButtonProps
	extends BorderProps,
		ColorProps,
		DisplayProps,
		FontFamilyProps,
		FontSizeProps,
		FontWeightProps,
		PaddingProps,
		BorderRadiusProps,
		WidthProps,
		MinWidthProps,
		MaxWidthProps,
		HeightProps,
		MarginProps,
		AlignSelfProps,
		JustifySelfProps {
	textDecoration?: string;
	bgColor?: keyof Colors;
	transparent?: boolean;
	pulse?: boolean;
}

// Used to detect when Button color needs to be white
const highContrastColors: (keyof Colors)[] = ['primaryBlue', 'secondaryBlue', 'primaryGreen', 'secondaryGreen', 'primaryOrange', 'primaryRed', 'secondaryRed'];

export const Button = styled(motion.button)<ButtonProps>`
	background-color: ${({ theme, bgColor, transparent }) => transparent ? 'transparent' : (
		bgColor ? theme.colors[bgColor] : theme.colors.bgTertiary
	)};
	
	color: ${({ theme, transparent, bgColor }) => transparent && bgColor ? theme.colors[bgColor] : (
		highContrastColors.includes(bgColor) ? 'white' : theme.colors.textPrimary
	)};

	height: max-content;
	text-decoration: ${({ textDecoration }) => textDecoration};
	transition: all 0.2s;
	padding: 8px;
	white-space: nowrap;

	${color};
	${display};
	${fontFamily};
	${fontSize};
	${fontWeight};
	${lineHeight};
	${padding};
	${margin};
	${alignSelf};
	${justifySelf};
	${border};
	${width};
	${minWidth};
	${maxWidth};
	${height};
	${borderRadius};

	&:hover {
		${({ transparent, bgColor }) => transparent ? css`
			background-color: ${({ theme }) => theme.colors[bgColor || 'accentPrimary']}19;
		` : css`
			opacity: 0.75;
		`}
	}

	&:disabled {
		opacity: 0.4;
		cursor: not-allowed;
	}

	justify-content: center;
	align-items: center;

	${({ pulse }) => pulse && 'animation: pulse 0.5s infinite alternate;'}

	@keyframes pulse {
		from {
			transform: scale(1);
		}
		to {
			transform: scale(1.1) translateZ(0);
			backface-visibility: hidden;
		}
	}
`

Button.defaultProps = {
	display: 'flex',
	fontFamily: 'body',
	fontSize: '12px',
	borderRadius: 4,
}

interface LoginButtonProps {
	margin?: string;
}

export const LoginLinkButton = styled.a<LoginButtonProps>`
	border: 1px solid ${({ theme }) => theme.current === 'light' ? theme.colors.accentPrimary : theme.colors.bgTertiary};
	background-color: ${({ theme }) => theme.current === 'light' ? theme.colors.bgSecondary : theme.colors.bgTertiary + 33};
	border-radius: 4px;
	display: flex;
	align-items: center;
	font-family: ${({ theme }) => theme.fonts.heading};
	margin-bottom: 30px;
	padding: 15px;
	text-transform: uppercase;
	text-decoration: none;
	transition: background-color 0.3s, border-color 0.3s, color 0.3s;
	width: fit-content;

	${({ theme }) => theme.current === 'light' && css`
		img {
			filter: invert(1);
		}
	`}

	${({ margin }) => margin && css`
		margin: ${margin};
	`}

	:hover {
		background-color: ${({ theme }) => theme.serviceColors.steam.primary};
		border-color: ${({ theme }) => theme.serviceColors.steam.primary};
		color: white;

		${({ theme }) => theme.current === 'light' && css`
			img {
				filter: none;
			}
		`}
	}
`;

export const LoginButton: FC<LoginButtonProps> = ({ margin }) => {
	const router = useRouter();

	return (
		<NextLink href={`${process.env.NEXT_PUBLIC_OAUTH_API_URL}/login?redirect_uri=${process.env.NEXT_PUBLIC_API_URL}/auth/new?return_path=${router.asPath}`} passHref>
			<LoginLinkButton margin={margin}>
				<Img src={'/img/steam-icon.svg'} width={'30px'} marginRight={'10px'}/>
				Login with Steam
			</LoginLinkButton>
		</NextLink>
	);
}