import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import {
	Banner,
	CreateClanProfileMutation,
	GlobalClanStats,
	IndividualStatisticsData,
	Leaderboard,
	LeaderboardData,
	LeaderboardQuery,
	LeaderboardTotals,
	Rule,
	RuleGroup,
	RuleImages,
	Server,
	ServerInfo,
	StatisticMappings,
	ClanInviteCode,
	ClanLeaderboardQuery,
	ClanMemberRoleMutation,
	ClanProfile,
	ClanProfileMutation,
	ClanProfileQuery,
	ClanStatisticsQuery,
	UserProfile,
	UserProfileMutation,
	UserStatisticsQuery,
	OAuthData,
	FindUsersDto,
	UpdateUserDto,
	IdUserData,
	AdminLog,
	LinkService,
	UnlinkRequest,
	LinkStats,
	UnlinkStatus,
	ClanJoinRequest,
	RankedClan,
	RankedClanProfile,
	MapServer,
	MapServerDetails,
} from '../utils'
import { CookieService } from './'

class ApiService {
	private readonly BASE_URL: string = process.env.NEXT_PUBLIC_API_URL
	private readonly pub: AxiosInstance // Axios instance for public fetch
	private readonly auth: AxiosInstance

	constructor() {
		const config: AxiosRequestConfig = {
			baseURL: this.BASE_URL,
		}

		const interceptorResponseFn = (response: AxiosResponse): AxiosResponse => response.data

		const interceptorErrorFn = (error) => {
			return Promise.reject({
				status: error?.response?.status,
				statusText: error?.response?.data?.message,
				message: error?.response?.statusText,
			})
		}

		this.pub = axios.create(config)
		this.pub.interceptors.response.use(interceptorResponseFn, interceptorErrorFn)

		this.auth = axios.create(config)
		this.auth.interceptors.request.use(
			(config) => {
				const token = CookieService.getAccessToken()

				if (token) {
					config.headers.Authorization = `Bearer ${token}`
				}

				return config
			},
			(err) => Promise.reject(err)
		)
		this.auth.interceptors.response.use(interceptorResponseFn, interceptorErrorFn)
	}

	async getUserProfile(includeAuthData?: boolean): Promise<UserProfile | null> {
		try {
			const user: UserProfile = await this.auth.get('users/me', {
				params: {
					includeAuthData,
				}
			});

			return user
		} catch (error) {
			return null
		}
	}

	async getUser(id: string): Promise<UserProfile> {
		if (id === undefined) {
			return null
		}

		try {
			const user: UserProfile = await this.auth.get(`users/${id}`)
			return user
		} catch (error) {
			return null
		}
	}

	async updateUser(userProfileMutation: UserProfileMutation): Promise<UserProfile> {
		try {
			const user: UserProfile = await this.auth.patch('users/me', userProfileMutation)
			return user
		} catch (error) {
			return null
		}
	}

	async getBanners(): Promise<Banner[]> {
		try {
			const banner: Banner[] = await this.auth.get('banners');
			return banner;
		} catch (error) {
			return null;
		}
	}

	async createClan(createClanProfileMutation: CreateClanProfileMutation): Promise<ClanProfile> {
		const formData = new FormData()
		formData.append('name', createClanProfileMutation.name)
		formData.append('image', createClanProfileMutation.image)

		const clan: ClanProfile = await this.auth.post('teams', formData)
		return clan
	}

	async getClan(data: ClanProfileQuery, token?: string): Promise<ClanProfile> {
		try {
			const clan: ClanProfile = await this.auth.get(`teams/${data.id ? '' : 'name/'}${data.id || data.name}`, token ? {
				headers: {
					'Authorization': `Bearer ${token}`
				}
			}: undefined);

			return clan;
		} catch (error) {
			return null
		}
	}

	async updateClan(clanProfileMutation: ClanProfileMutation): Promise<ClanProfile> {
		const formData = new FormData();

		if (clanProfileMutation.image) {
			formData.append('image', clanProfileMutation.image);

			if (clanProfileMutation.visibility) formData.append('visibility', clanProfileMutation.visibility);
			if (clanProfileMutation.bio) formData.append('bio', clanProfileMutation.bio);
		}

		try {
			const clan: ClanProfile = await this.auth.patch(`teams/my-team`, clanProfileMutation.image ? formData : {
				...clanProfileMutation,
			});

			return clan;
		} catch (error) {
			return null;
		}
	}

	async deleteClan() {
		try {
			await this.auth.delete('teams/my-team')
		} catch (error) {
			return null
		}
	}

	async updateClanMemberRole(clanMemberRoleMutation: ClanMemberRoleMutation): Promise<ClanProfile> {
		try {
			const clan: ClanProfile = await this.auth.patch(`teams/my-team/member-role`, clanMemberRoleMutation)
			return clan
		} catch (error) {
			return null
		}
	}

	async kickClanMember(id: string): Promise<ClanProfile> {
		try {
			const clan: ClanProfile = await this.auth.delete(`teams/my-team/member`, { data: { id } })
			return clan
		} catch (error) {
			return null
		}
	}

	async leaveClan(): Promise<ClanProfile> {
		const clan: ClanProfile = await this.auth.patch(`teams/my-team/leave`)
		return clan
	}

	async getClanInvites(): Promise<ClanInviteCode[]> {
		try {
			const invites: ClanInviteCode[] = await this.auth.get('/teams/invites');
			return invites;
		} catch {
			return [];
		}
	}

	async deleteClanInvite(id: string): Promise<ClanInviteCode[]> {
		try {
			return this.auth.delete(`teams/invite/${id}`);
		} catch {
			return [];
		}
	}

	async getClanInvite(id: string): Promise<ClanInviteCode> {
		if (id === null) {
			return null
		}

		try {
			const clanInvite: ClanInviteCode = await this.auth.get(`teams/invite/${id}`)
			return clanInvite
		} catch (error) {
			return null
		}
	}

	async createClanInviteCode(limit: number, expiresIn: number): Promise<ClanInviteCode> {
		try {
			const clanInviteCode: ClanInviteCode = await this.auth.post(`teams/invite`, {
				limit,
				expiresIn,
			});
			
			return clanInviteCode
		} catch (error) {
			return null
		}
	}

	async acceptClanInvite(id: string): Promise<void> {
		await this.auth.post(`teams/accept-invite`, {
			id,
		})
	}

	async getServers(clanId?: number): Promise<Server[]> {
		try {
			const servers: Server[] = await this.auth.get('servers', {
				params: {
					teamId: clanId,
				}
			});
			
			return servers;
		} catch (error) {
			return []
		}
	}

	async getUserServers(userId: string): Promise<Server[]> {
		try {
			const servers: Server[] = await this.auth.get(`/servers/filter/${userId}`);
			return servers;
		} catch (error) {
			return [];
		}
	}

	async getServer(id: string): Promise<Server> {
		if (id === undefined) {
			return null
		}

		try {
			const server: Server = await this.auth.get(`servers/find/${id}`)
			return server
		} catch (error) {
			return null
		}
	}

	async getSortedServers(): Promise<Server[]> {
		try {
			const servers: Server[] = await this.auth.get(`servers/sorted`)
			return servers
		} catch (error) {
			return []
		}
	}

	async getStatisticMappings(): Promise<StatisticMappings> {
		try {
			const statisticMappings: StatisticMappings = await this.auth.get(`statistics/mappings`)
			return statisticMappings
		} catch (error) {
			return null
		}
	}

	async getLeaderboardTotals(serverId: string, statisticId: string): Promise<LeaderboardTotals> {
		if (serverId === undefined || statisticId === undefined) {
			return null
		}

		try {
			const leaderboardTotals: LeaderboardTotals = await this.auth.get(
				`statistics/leaderboard-totals/${serverId}/${statisticId}`
			)
			return leaderboardTotals
		} catch {
			return null
		}
	}

	async getLeaderboard(leaderboardQuery: LeaderboardQuery): Promise<Leaderboard> {
		if (leaderboardQuery.serverId === undefined || leaderboardQuery.statisticId === undefined) {
			return null
		}

		try {
			const leaderboard: Leaderboard = await this.auth.get(
				`statistics/leaderboards/${leaderboardQuery.serverId}/${leaderboardQuery.statisticId}?from=${leaderboardQuery.from}&sortBy=${leaderboardQuery.sortBy}&orderBy=${leaderboardQuery.orderBy}&username=${leaderboardQuery.username}`
			)

			return leaderboard
		} catch (e) {
			return null
		}
	}

	async getUserStatistics(userStatisticsQuery: UserStatisticsQuery, checkAuth?: boolean): Promise<IndividualStatisticsData> {
		if (userStatisticsQuery.id === undefined || userStatisticsQuery.server === undefined) {
			return null;
		}

		try {
			const userStatisticsData: IndividualStatisticsData = await this.auth.get(`statistics/users/${userStatisticsQuery.id}/${userStatisticsQuery.server}`, {
				params: {
					checkAuth,
				}
			});

			return userStatisticsData;
		} catch (e) {
			return null;
		}
	}

	async getClanStatistics(clanStatisticsQuery: ClanStatisticsQuery): Promise<IndividualStatisticsData> {
		if (clanStatisticsQuery.id === undefined || clanStatisticsQuery.server === undefined) {
			return null
		}

		try {
			const clanStatisticsData: IndividualStatisticsData = await this.auth.get(
				`statistics/teams/${clanStatisticsQuery.id}/${clanStatisticsQuery.server}`
			)
			return clanStatisticsData
		} catch (e) {
			return null
		}
	}

	async getClanLeaderboardTotals(id: number, serverId: string, statisticId: string): Promise<LeaderboardTotals> {
		if (id === undefined || serverId === undefined || statisticId === undefined) {
			return null
		}

		try {
			const clanLeaderboardTotals: LeaderboardTotals = await this.auth.get(
				`statistics/teams/${id}/${serverId}/${statisticId}/leaderboard-totals`
			)
			return clanLeaderboardTotals
		} catch {
			return null
		}
	}

	async getClanLeaderboard(clanLeaderboardQuery: ClanLeaderboardQuery): Promise<LeaderboardData[]> {
		if (clanLeaderboardQuery.serverId === undefined || clanLeaderboardQuery.statisticId === undefined) {
			return null
		}

		try {
			const clanLeaderboardData: LeaderboardData[] = await this.auth.get(
				`statistics/teams/${clanLeaderboardQuery.id}/${clanLeaderboardQuery.serverId}/${clanLeaderboardQuery.statisticId}/leaderboards?sortBy=${clanLeaderboardQuery.sortBy}&orderBy=${clanLeaderboardQuery.orderBy}`
			)
			return clanLeaderboardData
		} catch (e) {
			return null
		}
	}

	async getGlobalClanStatistics(id: number): Promise<GlobalClanStats> {
		try {
			const clanStats: GlobalClanStats = await this.auth.get(`statistics/team-global/${id}`)
			return clanStats
		} catch (e) {
			return null
		}
	}

	async getServerInfo(): Promise<ServerInfo[]> {
		try {
			const serverInfo: ServerInfo[] = await this.auth.get('https://rustoria.co/serverinfo')
			return serverInfo
		} catch {
			return []
		}
	}

	async getRules(group: RuleGroup, serverId?: string): Promise<Rule[]> {
		try {
			const rules: Rule[] = await this.auth.get(`rules/${group}${serverId ? `?serverId=${serverId}` : ''}`)
			return rules
		} catch {
			return []
		}
	}

	async getRuleVideos(): Promise<RuleImages> {
		try {
			const ruleImages: RuleImages = await this.auth.get('rules/images')
			return ruleImages
		} catch {
			return { vanilla: null, modded: null, training: null }
		}
	}

	async findLinkedUsers(findUsersDto: FindUsersDto, skip?: number): Promise<{ users: OAuthData[]; total: number; self: IdUserData }> {
		if (findUsersDto.permissionLevel) {
			delete findUsersDto.permissionLevelOver;
			delete findUsersDto.permissionLevelUnder;
		}

		if (findUsersDto.service === 'rustoria') {
			delete findUsersDto.service;
		}

		try {
			const users: { users: OAuthData[]; total: number; self: IdUserData } = await this.auth.get('link/admin/users', {
				params: {
					...findUsersDto,
					skip,
				},
			});

			return users;
		} catch {
			return {
				users: [],
				total: 0,
				self: null,
			};
		}
	}

	async updateLinkedUser(updateUserDto: UpdateUserDto): Promise<OAuthData> {
		try {
			const updatedUser: OAuthData = await this.auth.patch('link/admin/user', updateUserDto);
			return updatedUser;
		} catch {
			return null;
		}
	}

	async reindexUser(id: string | number, service: 'steam' | 'discord') {
		try {
			await this.auth.patch('link/admin/reindex', { id, service });
			return true;
		} catch {
			return false;
		}
	}

	async findAdminLogs(skip?: number): Promise<{ logs: AdminLog[]; self: IdUserData, admins: OAuthData[] }> {
		try {
			const response: { logs: AdminLog[]; self: IdUserData, admins: OAuthData[] } = await this.auth.get('link/admin/logs', {
				params: {
					skip
				},
			});
			return response;
		} catch {
			return {
				logs: [],
				self: null,
				admins: [],
			};
		}
	}

	async unlinkAccount(service: LinkService): Promise<number> {
		try {
			let response = await this.auth.post('link/admin/unlink', { service });
			return response.status;
		} catch (err) {
			return err.status;
		}
	}

	async getAdminUnlinks(): Promise<{ unlinks: UnlinkRequest[], self: IdUserData }> {
		try {
			const unlinks: { unlinks: UnlinkRequest[], self: IdUserData } = await this.auth.get('link/admin/unlinks');
			return unlinks;
		} catch {
			return {
				unlinks: [],
				self: null,
			};
		}
	}

	async getUserUnlinks(): Promise<UnlinkRequest[]> {
		try {
			return this.auth.get('link/unlinks');
		} catch {
			return [];
		}
	}

	async getLinkStats(): Promise<LinkStats> {
		try {
			return this.auth.get('link/admin/stats');
		} catch {
			return null;
		}
	}

	async sendUnlinkRequest(service: LinkService, reason: string, explanation: string) {
		try {
			const response = await this.auth.post('link/unlink', {
				service,
				reason,
				explanation,
			});

			return response?.status || response;
		} catch (err) {
			return err?.status;
		}
	}

	async respondToUnlink(requestId: string, status: UnlinkStatus, notes?: string) {
		try {
			await this.auth.patch('link/admin/unlink', {
				requestId,
				status,
				notes,
			});

			return true;
		} catch {
			return false;
		}
	}

	async deleteUnlinkRequest(requestId: string) {
		try {
			await this.auth.delete('link/unlink', {
				params: {
					requestId,
				}
			});

			return true;
		} catch {
			return false;
		}
	}

	async claimServiceToken(serviceToken: string) {
		try {
			const response = await this.auth.post('link/claim', {
				serviceToken,
			});

			return response;
		} catch (err) {
			return err?.status;
		}
	}

	async getTopClans(serverId?: string): Promise<RankedClanProfile[]> {
		try {
			const clans: RankedClanProfile[] = await this.auth.get('statistics/teams/top', {
				params: {
					serverId,
				}
			});
			
			return clans;
		} catch {
			return [];
		}
	}

	async createClanJoinRequest(clanId: number, message?: string) {
		return await this.auth.post(`teams/${clanId}/requests`, {
			message,
		});
	}

	async getClanJoinRequests(): Promise<ClanJoinRequest[]> {
		try {
			return this.auth.get('teams/requests');
		} catch {
			return [];
		}
	}

	async respondToClanJoinRequest(id: string, action: 'accept'|'deny'): Promise<ClanJoinRequest[]> {
		return this.auth.patch(`teams/requests/${id}`, {
			action,
		});
	}

	async getRankedClans(skip?: number, searchValue?: string, serverId?: string): Promise<RankedClan[]> {
		try {
			return this.auth.get('statistics/teams/ranked', {
				params: {
					skip,
					search: searchValue,
					serverId,
				}
			});
		} catch {
			return [];
		}
	}

	async getUserJoinRequests(): Promise<ClanJoinRequest[]> {
		try {
			return this.auth.get('teams/requests/me');
		} catch {
			return [];
		}
	}

	async checkClanName(name: string): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			this.auth.get('teams/check-name', {
				params: {
					name,
				}
			}).then(() => {
				resolve(true);
			}).catch(() => {
				resolve(false);
			});
		});
	}

	async getMapServers(): Promise<MapServer[]> {
		try {
			return this.auth.get('maps/servers');
		} catch {
			return [];
		}
	}

	async getMapServer(serverId: string): Promise<MapServerDetails> {
		try {
			return this.auth.get(`maps/servers/${serverId}`);
		} catch {
			return null;
		}
	}

	async submitMapVote(serverId: string, mapId: string) {
		return this.auth.post(`maps/servers/${serverId}/vote`, {
			mapId,
		});
	}

	async checkDonator() {
		try {
			const data = await this.auth.get<any, { isDonator: boolean }>('maps/user/donator');
			return data.isDonator;
		} catch {
			return false;
		}
	}
}

export default new ApiService()
