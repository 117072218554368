export enum VisibilityType {
	TEAM = 'team',
	STATS = 'stats',
	ALL = 'all',
}

export type ClanRole = 'owner' | 'moderator' | 'member';

export interface UserProfile {
	id: string
	rustoriaId: string
	username: string
	avatar: string
	private: boolean
	visibility: VisibilityType
	team?: ClanProfile
	role?: ClanRole,
	permissionLevel?: number,
}

export interface UserProfileMutation {
	private?: boolean
	visibility?: VisibilityType
}

export interface ClanProfile {
	id: number
	name: string
	image?: string
	visibility: VisibilityType
	users?: UserProfile[]
	bio?: string
	private: boolean;
}

export interface CreateClanProfileMutation {
	image?: File
	name: string
}

export interface ClanProfileQuery {
	id?: number
	name?: string
}

export interface ClanProfileMutation {
	visibility?: VisibilityType
	image?: File
	bio?: string
	private?: boolean;
	name?: string;
}

export interface ClanMemberRoleMutation {
	id: string;
	role: ClanRole;
}

export interface ClanInviteCode {
	id: string;
	team: ClanProfile;
	uses: number;
	limit: number;
	expiresAt: string;
	creator?: UserProfile;
}

export interface Server {
	id: string
	name: string
	image?: string
	serverType?: 'vanilla' | 'modded'
	groupLimit?: number
	excludeGroupRules?: boolean
	statistics?: Statistic[]
}

export enum StatisticDisplayType {
	NAME = 'name',
	IMAGE = 'image',
}

export interface Statistic {
	id: string
	name: string
	composition: string
	display: StatisticDisplayType
}

export interface StatisticMappings {
	[id: string]: {
		name: string
		image?: string
	}
}

export interface LeaderboardTotals {
	total: number
	fieldTotals: {
		[id: string]: number
	}
}

export interface LeaderboardQuery {
	serverId: string
	statisticId: string
	from: number
	sortBy: string
	orderBy: 'asc' | 'desc'
	username?: string
}

export interface LeaderboardData {
	userId: string
	username: string
	avatar: string
	private: boolean
	total: number
	data: {
		[id: string]: number
	}
}

export interface Leaderboard {
	leaderboard: LeaderboardData[]
	totalItems: number
}

export interface UserStatisticsQuery {
	id: string
	server: string
}

export interface ClanStatisticsQuery {
	id: number
	server: string
}

export interface ClanLeaderboardQuery {
	id: number
	serverId: string
	statisticId: string
	sortBy: string
	orderBy: 'asc' | 'desc'
}

export interface IndividualStatisticsData {
	[id: string]: {
		total: number
		data: {
			[id: string]: number
		}
	}
}

export interface ServerInfo {
	id: string
	ip: string
	port: number
	status: boolean
	name: string
	joining: number
	queued: number
	players: number
	maxPlayers: number
	entityCount: number
	fps: number
	wipedAt: string
}

export interface UserData {
	id: string
	created_at: string
	updated_at: string
	deleted_at: string
	donator: boolean
	permission_level: boolean
	v: number
	steam: {
		id: number
		name: string
		linked_at: string
		follower: boolean
		avatar: string
	}
	discord: {
		id: number
		name: string
		linked_at: string
		booster: boolean
		boost_changed_at: string
		follower: boolean
		avatar: string
	}
	twitter: {
		id: number
		name: string
		linked_at: string
		follower: boolean
	}
	twitch: {
		id: number
		name: string
		linked_at: string
		follower: boolean
		avatar: string
	}
	youtube: {
		id: number
		name: string
		linked_at: string
		follower: boolean
		avatar: string
	}
}

export interface Banner {
	id: string
	link: string
	image: string
}

export interface Rule {
	id?: number
	title: string
	description: string
	group?: RuleGroup
}

export type RuleGroup = 'global' | 'vanilla' | 'modded' | 'training' | 'server'

export interface RuleImages {
	vanilla: string
	modded: string
	training: string
}

export interface GlobalClanStats {
	kdr: number
	kills: number
	deaths: number
	resourcesGathered: number
}

export interface TutorialData {
	enabled: boolean
	section?: 'invite' | 'manage' | 'bio' | 'share' | 'complete'
}

export type LinkService = 'youtube' | 'twitch' | 'steam' | 'twitter' | 'discord';

export interface OAuthData {
	id: string;
	permissionLevel: number;
	createdAt: string;
	updatedAt: string;
	donator: boolean;
	v: number;
	alias?: string;
	steam: {
		id: number;
		idStr: string;
		name: string;
		avatar: string;
		linkedAt: string;
		follower: boolean;
	};
	discord?: {
		id: number;
		name: string;
		avatar: string;
		linkedAt: string;
		boostedAt: string;
		follower: boolean;
		booster: boolean;
	};
	twitter?: {
		id: string;
		name: string;
		follower: boolean;
		linkedAt: string;
	};
	youtube?: {
		id: string;
		name: string;
		avatar: string;
		follower: boolean;
		linkedAt: string;
	};
	twitch?: {
		id: number;
		name: string;
		avatar: string;
		follower: boolean;
		linkedAt: string;
	};
}

export interface IdUserData {
	id: string;
	permission_level: number;
	created_at: string;
	updated_at: string;
	donator: boolean;
	v: number;
	steam: {
		avatar: string;
		id: number;
		idStr: string;
		linked_at: string;
		name: string;
	};
	discord?: {
		id: number;
		name: string;
		avatar: string;
		linked_at: string;
		updated_at: string;
		boost_changed_at: string;
		follower: boolean;
		booster: boolean;
	};
	twitter?: {
		id: string;
		name: string;
		follower: boolean;
		linked_at: string;
		updated_at: string;
	};
	youtube?: {
		id: string;
		name: string;
		avatar: string;
		follower: boolean;
		linked_at: string;
		updated_at: string;
	};
	twitch?: {
		id: number;
		name: string;
		avatar: string;
		follower: boolean;
		linked_at: string;
		updated_at: string;
	};
}

export type FindUsersLimit = 10 | 100 | 250 | 500 | 750 | 1000;

export interface FindUsersDto {
	service?: LinkService | 'rustoria' | 'all';
	searchValue?: string;
	permissionLevel?: number;
	permissionLevelOver?: number;
	permissionLevelUnder?: number;
	donator?: boolean;
	booster?: boolean;
	limit?: FindUsersLimit;
	usernameSearch: boolean;
}

export interface UpdateUserDto {
	id: string;
	permissionLevel?: number;
	alias?: string;
	unlinks?: UnlinkRequestDto[];
}

export interface UnlinkRequestDto {
	service: LinkService;
	id: string | number;
}

export type LogAction = 'demote' | 'promote' | 'unlink';

export interface AdminLog {
	action: LogAction;
	timestamp: number;
	executorId: string;
	targetId: string;
	executor: OAuthData;
	target: OAuthData;
	service?: LinkService;
	oldLevel?: number;
	newLevel?: number;
}

export interface LogFilter {
	admin?: string;
	action?: LogAction;
}

export type UnlinkStatus = 0 | 1 | 2 | 3;

export interface UnlinkRequest {
	id: string;
	status: UnlinkStatus;
	requester: string;
	service: LinkService;
	createdAt: string;
	updatedAt: string;
	notes: string;
	reason: string;
	explanation: string;
	steam: {
		id: string;
		name: string;
		avatar: string;
	}
	serviceData: {
		id: string;
		name: string;
		follower: boolean;
		linkedAt: string;
	}
}

export interface LinkStatSection {
	links: {
		[key in LinkService]: number;
	},
	api: {
		tokenRefresh: number;
	},
	unlinks: {
		total: number;
		approved: number;
		denied: number;
	}
}

export interface LinkStats {
	all: LinkStatSection;
	year: LinkStatSection;
	quarter: LinkStatSection;
	month: LinkStatSection;
	week: LinkStatSection;
	day: LinkStatSection;
}

export interface ClanJoinRequest {
	id: string;
	team: ClanProfile;
	creator: UserProfile;
	createdAt: string;
	updatedAt: string;
	message?: string;
}

export interface RankedClan {
	points: number;
	team: RankedClanProfile;
	rank: number;
}

export interface RankedClanProfile extends ClanProfile {
	users: RankedClanMember[];
	stats: ClanStatistics;
}

interface RankedClanMember extends UserProfile {
	stats: ClanStatistics;
}

interface ClanStatistics {
	kills: number;
	rockets: number;
	sulfur: number;
}

export interface SelectOption {
	value: string;
	label: string;
	image?: string;
}

export interface Map {
	id: string;
	imageUrl: string;
	thumbnailUrl: string;
	rustMapsUrl: string;
	votes?: number;
}

export interface MapServer {
	id: string;
	displayName: string;
	logoUrl: string;
	votingOpen: boolean;
	voteOpensDaysBeforeWipe: 0;
	ends: string;
}

export interface MapServerDetails extends MapServer {
	maps: Map[];
	hasVoted: boolean;
}