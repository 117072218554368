import { useMutation, useQuery } from 'react-query'

import { ApiService } from '@services'
import {
	ClanProfile,
	ClanProfileMutation,
	ClanMemberRoleMutation,
	CreateClanProfileMutation,
	ClanStatisticsQuery,
	ClanLeaderboardQuery,
	ClanInviteCode,
	RankedClan,
} from '@utils'
import { Dispatch, useEffect, useState } from 'react'

export function useCreateClan({ onSuccess, onError }) {
	return useMutation(
		'teams',
		async (createClanProfileMutation: CreateClanProfileMutation) =>
			await ApiService.createClan(createClanProfileMutation),
		{ onSuccess, onError }
	)
}

export function useGetClan(id: number, initialClan: ClanProfile, { onSuccess }) {
	return useQuery(['teams', id], async () => await ApiService.getClan({ id }), {
		initialData: initialClan,
		onSuccess,
	})
}

export function useGetClanStatistics(clanStatisticsQuery: ClanStatisticsQuery) {
	return useQuery(
		['statistics/teams', clanStatisticsQuery.id, clanStatisticsQuery.server],
		async () => await ApiService.getClanStatistics(clanStatisticsQuery)
	)
}

export function useGetGlobalClanStatistics(id: number) {
	return useQuery(['statistics/team-global', id], async () => await ApiService.getGlobalClanStatistics(id))
}

export function useGetClanLeaderboardTotals(id: number, serverId: string, statisticId: string) {
	return useQuery(
		['statistics/teams', id, serverId, statisticId, 'leaderboard-totals'],
		async () => await ApiService.getClanLeaderboardTotals(id, serverId, statisticId)
	)
}

export function useGetClanLeaderboard(clanLeaderboardQuery: ClanLeaderboardQuery) {
	return useQuery(
		[
			'statistics/teams',
			clanLeaderboardQuery.id,
			clanLeaderboardQuery.serverId,
			clanLeaderboardQuery.statisticId,
			'leaderboards',
			clanLeaderboardQuery.sortBy,
			clanLeaderboardQuery.orderBy,
		],
		async () => await ApiService.getClanLeaderboard(clanLeaderboardQuery),
		{ keepPreviousData: true }
	)
}

export function useUpdateClan({ onSuccess }) {
	return useMutation(
		'teams/my-team',
		async (clanProfileMutation: ClanProfileMutation) => await ApiService.updateClan(clanProfileMutation),
		{ onSuccess }
	)
}

export function useDeleteClan({ onSuccess }) {
	return useMutation('teams/my-team$delete', async () => await ApiService.deleteClan(), { onSuccess })
}

export function useUpdateClanMemberRole({ onSuccess }) {
	return useMutation(
		'teams/my-team',
		async (clanMemberRoleMutation: ClanMemberRoleMutation) =>
			await ApiService.updateClanMemberRole(clanMemberRoleMutation),
		{ onSuccess }
	)
}

export function useKickClanMember({ onSuccess }) {
	return useMutation('teams/my-team/member', async (id: string) => await ApiService.kickClanMember(id), {
		onSuccess,
	})
}

export function useLeaveClan({ onSuccess, onError }) {
	return useMutation('teams/my-team/leave', async () => await ApiService.leaveClan(), { onSuccess, onError })
}

export function useCreateClanInviteCode(limit: number, expiresIn: number, onSuccess?: (invite: ClanInviteCode) => any) {
	return useMutation('teams/invite', async () => await ApiService.createClanInviteCode(limit, expiresIn), { onSuccess });
}

export function useAcceptClanInvite({ onSuccess, onError }) {
	return useMutation('teams', async (id: string) => await ApiService.acceptClanInvite(id), {
		onSuccess,
		onError,
	})
}

export function useClanInvites({ onSuccess }) {
	return useQuery(
		'team/invites',
		async () => await ApiService.getClanInvites(),
		{ onSuccess },
	);
}

export function useDeleteClanInvite({ onSuccess, onMutate }) {
	return useMutation('teams', (async (id: string) => await ApiService.deleteClanInvite(id)), { onSuccess, onMutate });
}

export function useClanRequests({ onSuccess }, disabled?: boolean) {
	return useQuery('team/requests', async () => await ApiService.getClanJoinRequests(), { onSuccess, initialData: [], enabled: !disabled });
}

export function useAcceptClanRequest({ onSuccess }) {
	return useMutation((id: string) => {
		return ApiService.respondToClanJoinRequest(id, 'accept');
	}, { onSuccess });
}

export function useDenyClanRequest({ onSuccess }) {
	return useMutation((id: string) => {
		return ApiService.respondToClanJoinRequest(id, 'deny');
	}, { onSuccess });
}

export function useRankedClans(skip: number, setSkip: Dispatch<number>, searchValue?: string, serverId?: string) {
	const [clans, setClans] = useState<RankedClan[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isResultsEmpty, setIsResultsEmpty] = useState<boolean>(false);

	useEffect(() => {
		setClans([]);
		setSkip(0);
		setIsLoading(true);
		setIsResultsEmpty(false);

		ApiService.getRankedClans(0, searchValue, serverId).then((c) => {
			setClans(c);
			setIsLoading(false);
		});
	}, [searchValue, serverId]);

	useEffect(() => {
		if (!isResultsEmpty && !isLoading) {
			setIsLoading(true);
			ApiService.getRankedClans(skip, searchValue, serverId).then((c) => {
				setClans([].concat(clans, c));
				setIsLoading(false);

				if (c.length < 20) {
					setIsResultsEmpty(true);
				}
			});
		}
	}, [skip]);

	return { clans, isLoading };
}

export function useUserClanRequests() {
	return useQuery('team/requests/me', async () => await ApiService.getUserJoinRequests(), { initialData: [] });
}

export function useCheckClanName(name: string) {
	const [isValid, setIsValid] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		setIsLoading(true);
		ApiService.checkClanName(name).then((res) => {
			setIsValid(res);
		}).finally(() => {
			setIsLoading(false);
		});
	}, [name]);

	return { isValid, isLoading };
}