import styled, { css } from 'styled-components';

interface TipProps {
    tipPosition?: 'left'|'right'|'top';
    show: boolean;
    margin?: string;
}

export const Tip = styled.div<TipProps>`
    background-color: #212020;
    box-shadow: 0px 5px 15px 5px rgba(0,0,0,0.25);
    border-radius: 20px;
    color: white;
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 13px;
    line-height: 1;
    z-index: 1000;
    white-space: nowrap;
    padding: 6px 10px;

    ${({ show }) => show && 'display: block;'}

    ${({ tipPosition, margin }) => tipPosition === 'right' ? css`
        /* Position to the right */
        left: calc(100% + ${margin ? margin : '10px'});
        top: 50%;
        transform: translateX(0) translateY(-52%);
        animation: tipFadeRight 0.25s;
    ` : (
        tipPosition === 'top' ? css`
            /* Position to the top */
            top: calc(30px * -1);
            animation: tipFadeTop 0.25s;  
        ` : css`
            /* Position to the left */
            left: auto;
            right: calc(100% + 10px);
            top: 50%;
            transform: translateX(0) translateY(-50%);
            animation: tipFadeLeft 0.25s;  
        `
    )}

    @keyframes tipFadeLeft {
		from {
			opacity: 0;
			transform: translateX(10px) translateY(-50%);
		}
		to {
			opacity: 1;
			transform: translateX(0) translateY(-50%);
		}
	}

    @keyframes tipFadeRight {
		from {
			opacity: 0;
			transform: translateX(-10px) translateY(-50%);
		}
		to {
			opacity: 1;
			transform: translateX(0) translateY(-50%);
		}
	}

    @keyframes tipFadeTop {
		from {
			opacity: 0;
			transform: translateX(-50%) translateY(10px);
		}
		to {
			opacity: 1;
			transform: translateX(-50%) translateY(0px);
		}
	}
`

export const TipWrapper = styled.div`
    display: inline-block;
    position: relative;

    /* :hover {
        ${Tip} {
            display: block;
        }
    } */
`