const getRange = (start, end) => {
	return new Array(end - start + 1).fill(null).map((v, i) => i + start)
}

export const calculatePaginationList = (current, length, delta = 3): number[] => {
	const range = {
		start: Math.round(current - delta / 2),
		end: Math.round(current + delta / 2),
	}

	if (range.start - 1 === 1 || range.end + 1 === length) {
		range.start += 1
		range.end += 1
	}

	const pages =
		current > delta
			? getRange(Math.min(range.start, length - delta), Math.min(range.end, length))
			: getRange(1, Math.min(length, delta + 1))

	return pages
}
