import { FC } from 'react';
import { SelectorOption, SelectorWrapper } from './styles';

interface BooleanSelectorProps {
	value: boolean | null;
	onChange: (value: boolean) => void;
	isBinary?: boolean;
}

export const BooleanSelector: FC<BooleanSelectorProps> = ({ value, onChange, isBinary }) => {
	const handleClick = (newValue?: boolean) => {
		if (value !== newValue) {
			onChange(newValue);
		}
	};

	return (
		<SelectorWrapper>
			<SelectorOption selected={value === false} onClick={() => handleClick(false)} buttonType={false}>
				No
			</SelectorOption>
			{isBinary !== true && (
				<SelectorOption
					selected={value === null || value === undefined}
					onClick={() => handleClick(undefined)}
					buttonType={null}
				>
					Meh
				</SelectorOption>
			)}
			<SelectorOption selected={value === true} onClick={() => handleClick(true)} buttonType={true}>
				Yes
			</SelectorOption>
		</SelectorWrapper>
	);
};
