import { FC } from 'react'
import styled from 'styled-components'

const BannerWrapper = styled.a`
	display: flex;
	justify-content: flex-end;
	margin-right: -5px;
	margin-top: 2.5px;
	width: 100%;

	@media (max-width: 1200px) {
		justify-content: flex-start;
		margin-left: -10px;
		margin-top: 0px;
	}

	@media (max-width: 700px) {
		justify-content: center;
		margin-left: 0;
	}

	@media (max-width: 678px) {
		justify-content: center;
	}
`

const BannerImage = styled.img`
	width: auto;
	max-width: 500px;

	@media (max-width: 678px) {
		max-width: 360px;
		margin-top: 10px;
	}
`

interface BannerProps {
	link: string
	image: string
}

const Banner: FC<BannerProps> = ({ link, image }) => {
	return (
		<BannerWrapper href={link} target='_blank'>
			<BannerImage src={image} />
		</BannerWrapper>
	)
}

export default Banner
