import { IdUserData, OAuthData } from '@utils'
import axios, { AxiosInstance } from 'axios'

class IdService {
	private readonly auth: AxiosInstance

	constructor() {
		this.auth = axios.create({
			baseURL: process.env.NEXT_PUBLIC_OAUTH_API_URL,
		})
	}

	async getUserData() {
		try {
			const oauthResponse = await this.auth.get<IdUserData>('data', {
				withCredentials: true,
				xsrfHeaderName: 'x-api-key',
			})

			return oauthResponse.data
		} catch {
			return null
		}
	}
}

export default new IdService()
