import { useMutation, useQuery } from 'react-query'
import { ApiService } from '@services'
import { UserProfile, UserProfileMutation, UserStatisticsQuery } from '@utils'
import { useEffect, useState } from 'react'
import { useAuthContext } from '@context';

export function useGetMe() {
	const [user, setUser] = useState<UserProfile>();
	const { user: userProfile } = useAuthContext();

	useEffect(() => {
		ApiService.getUserProfile().then((res) => {
			setUser(res);
		});
	}, [userProfile]);

	return user;
}

export function useGetUserProfile(initialUser?: UserProfile) {
	return useQuery('users/me', async () => await ApiService.getUserProfile(), { initialData: initialUser })
}

export function useGetUser(id: string, initialUser: UserProfile, { onSuccess }) {
	return useQuery(['users', id], async () => await ApiService.getUser(id), {
		initialData: initialUser,
		onSuccess,
	})
}

export function useGetUserStatistics(userStatisticsQuery: UserStatisticsQuery, checkAuth?: boolean) {
	return useQuery(
		['statistics/users', userStatisticsQuery.id, userStatisticsQuery.server],
		async () => await ApiService.getUserStatistics(userStatisticsQuery, checkAuth)
	)
}

export function useUpdateUser({ onSuccess }) {
	return useMutation(
		'users/me',
		async (userProfileMutation: UserProfileMutation) => await ApiService.updateUser(userProfileMutation),
		{ onSuccess }
	)
}
