import { FC } from 'react'
import { LinkWrapper, Link, CopyButton } from './styles'
import { copyText } from '@utils'
import toast from 'react-hot-toast'

interface CopyLinkProps {
	link: string;
	toastMessage?: string;
}

export const CopyLink: FC<CopyLinkProps> = ({ link, toastMessage }) => {
	const handleCopy = () => {
		copyText(link);
		toast.success(toastMessage || 'Link copied');
	}

	return (
		<LinkWrapper>
			<Link id={link} value={link} readOnly />
			<CopyButton onClick={handleCopy}>COPY</CopyButton>
		</LinkWrapper>
	)
}
