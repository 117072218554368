import { useQuery } from 'react-query'
import { ApiService } from '@services'
import { LeaderboardQuery } from '@utils'

export function useGetLeaderboardTotals(id: string, statisticId: string) {
	return useQuery(
		['statistics/leaderboard-totals/', id, statisticId],
		async () => await ApiService.getLeaderboardTotals(id, statisticId)
	)
}

export function useGetLeaderboard(leaderboardQuery: LeaderboardQuery) {
	return useQuery(
		[
			'statistics/leaderboard/',
			leaderboardQuery.serverId,
			leaderboardQuery.statisticId,
			leaderboardQuery.from,
			leaderboardQuery.sortBy,
			leaderboardQuery.orderBy,
			leaderboardQuery.username,
		],
		async () => await ApiService.getLeaderboard(leaderboardQuery),
		{ refetchOnWindowFocus: false, keepPreviousData: true }
	)
}
