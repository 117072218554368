import styled from 'styled-components';
import { alignItems, AlignItemsProps, flexDirection, FlexDirectionProps, justifyContent, JustifyContentProps, margin, MarginProps } from 'styled-system';

interface FlexProps extends FlexDirectionProps, JustifyContentProps, AlignItemsProps, MarginProps {};

export const Flex = styled.div<FlexProps>`
	display: flex;
	${flexDirection};
	${justifyContent};
	${alignItems};
	${margin};
`;