import { ApiService } from '@services'
import { Rule, RuleGroup } from '@utils'
import { useQuery } from 'react-query'
import { sanitizeRules } from 'utils/sanitize'

export function useGetRules(group: RuleGroup, serverId?: string) {
	const rules: Rule[] = useQuery(
		['servers', group, serverId],
		async () => await ApiService.getRules(group, serverId),
		{
			initialData: [],
			keepPreviousData: false,
		}
	).data

	return sanitizeRules(rules)
}
