import { AnimatePresence } from 'framer-motion';
import { Dispatch, FC, useState } from 'react';
import useMeasure from 'react-use-measure';
import { FlattenInterpolation, FlattenSimpleInterpolation } from 'styled-components';
import { Icon } from '../Icon';
import { ModalWrapper, ModalHeader, ModalBox, ModalBody, ModalCloseButton, ModalFooter } from './styles';

interface ModalComponentProps {
	isOpen: boolean;
	setIsOpen: Dispatch<boolean>;
	header: JSX.Element|string;
	footer?: JSX.Element;
	customStyles?: FlattenSimpleInterpolation | FlattenInterpolation<any>;
}

export const Modal: FC<ModalComponentProps> = ({ isOpen, setIsOpen, children, header, footer, customStyles }) => {
	const [isHovered, setIsHovered] = useState<boolean>(false);
	const [ref, bounds] = useMeasure();

	const handleClick = () => {
		if (!isHovered) {
			setIsOpen(false);
		}
	}

	return (
		<ModalWrapper isOpen={isOpen} onClick={handleClick}>
			<AnimatePresence>
				{isOpen && (
						<ModalBox
							customStyles={customStyles}
							onMouseOver={() => setIsHovered(true)}
							onMouseLeave={() => setIsHovered(false)}
							initial={{ scale: 0, opacity: 0 }}
							animate={{ height: bounds.height || 'auto', scale: 1, opacity: 1 }}
							exit={{ scale: 0, opacity: 0 }}
							transition={{ duration: 0.25 }}
						>
							<div ref={ref}>
								<ModalHeader>
									{header}
									<ModalCloseButton onClick={() => setIsOpen(false)}>
										<Icon className={'fas fa-times'} fontSize={20}/>
									</ModalCloseButton>
								</ModalHeader>
								<ModalBody>
									{children}
								</ModalBody>
								{footer && (
									<ModalFooter>
										{footer}
									</ModalFooter>
								)}
							</div>
						</ModalBox>
				)}
			</AnimatePresence>
		</ModalWrapper>
	);
}