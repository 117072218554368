import { ApiService } from '@services';
import { AdminLog, FindUsersDto, FindUsersLimit, getRelativeTime, IdUserData, OAuthData } from '@utils';
import { useRouter } from 'next/router';
import { Dispatch, useEffect, useState } from 'react';

interface UseFindUsersReturn {
	users: OAuthData[];
	setUsers: Dispatch<OAuthData[]>;
	total: number;
	self: IdUserData;
	isLoading: boolean;
	isLoadingMore: boolean;
	allResultsFound: boolean;
}

export function useFindUsers(findUsersDto: FindUsersDto, skip: number, setSkip: Dispatch<number>, setSelectedUser: Dispatch<OAuthData>, onSkipSuccess?: (n: number) => any, onSkipFail?: () => any): UseFindUsersReturn {
	const [users, setUsers] = useState<OAuthData[]>([]);
	const [total, setTotal] = useState<number>(0);
	const [self, setSelf] = useState<IdUserData>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
	const [allResultsFound, setAllResultsFound] = useState<boolean>(false);
	const [oldLimit, setOldLimit] = useState<FindUsersLimit>(100);
	const router = useRouter();

	useEffect(() => {
		setIsLoading(true);
		if (findUsersDto.limit !== oldLimit) setSkip(0);
		setOldLimit(findUsersDto.limit);
		ApiService.findLinkedUsers(JSON.parse(JSON.stringify(findUsersDto))).then((usersResponse) => {
			if (!usersResponse.self || usersResponse?.self?.permission_level < 8) router.push('/link');

			setUsers(usersResponse.users);
			setTotal(usersResponse.total);
			setSelf(usersResponse.self);
			setIsLoading(false);

			if (usersResponse.users.length === 1) {
				setSelectedUser(usersResponse.users[0]);
			}

			if (usersResponse.users.length < findUsersDto.limit) {
				setAllResultsFound(true);
			} else {
				setAllResultsFound(false);
			}
		});
	}, [findUsersDto]);

	useEffect(() => {
		if (skip > 0) {
			setIsLoadingMore(true);
			ApiService.findLinkedUsers(JSON.parse(JSON.stringify(findUsersDto)), skip * findUsersDto.limit).then((res) => {
				if (res.users.length > 0) {
					let newUsers = [...users].concat(res.users);
					setUsers(newUsers);
					setTotal(res.total);

					if (onSkipSuccess) onSkipSuccess(res.users.length);
					if (res.users.length < skip * findUsersDto.limit) setAllResultsFound(true);
				} else {
					setAllResultsFound(true);
					if (onSkipFail) onSkipFail();
				}

				setIsLoadingMore(false);
			});
		}
	}, [skip]);

	return {
		users,
		setUsers,
		total,
		self,
		isLoading,
		isLoadingMore,
		allResultsFound
	};
}

interface UseFindLogsReturn {
	logs: AdminLog[],
	setLogs: Dispatch<AdminLog[]>,
	self: IdUserData,
	admins: OAuthData[],
	isLoading: boolean,
	isLoadingMore: boolean,
	allResultsFound: boolean;
}

export function useFindLogs(skip: number, onSkipSuccess?: (n: number) => any, onSkipFail?: () => any): UseFindLogsReturn {
	const [logs, setLogs] = useState<AdminLog[]>([]);
	const [self, setSelf] = useState<IdUserData>();
	const [admins, setAdmins] = useState<OAuthData[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
	const [allResultsFound, setAllResultsFound] = useState<boolean>(false);
	const router = useRouter();

	const DISPLAY_AMOUNT = 50;

	useEffect(() => {
		ApiService.findAdminLogs().then((response) => {
			setLogs(response.logs);
			setSelf(response.self);
			setAdmins(response.admins);
			setIsLoading(false);

			if (!response.self || response?.self?.permission_level < 11) router.push('/link');

			if (!allResultsFound && response.logs.length < DISPLAY_AMOUNT) {
				setAllResultsFound(true);
			}
		});
	}, []);

	useEffect(() => {
		if (skip > 0) {
			setIsLoadingMore(true);
			ApiService.findAdminLogs(skip * DISPLAY_AMOUNT).then((response) => {
				if (response.logs.length > 0) {
					let newLogs = [...logs].concat(response.logs);
					setLogs(newLogs);

					let newAdmins = new Set([...admins].concat(response.admins));
					setAdmins(Array.from(newAdmins));

					if (onSkipSuccess) onSkipSuccess(response.logs.length);
					if (response.logs.length < skip * DISPLAY_AMOUNT) setAllResultsFound(true);
				} else {
					setAllResultsFound(true);
					if (onSkipFail) onSkipFail();
				}

				setIsLoadingMore(false);
			});
		}
	}, [skip]);

	return {
		logs,
		setLogs,
		self,
		admins,
		isLoading,
		isLoadingMore,
		allResultsFound,
	};
}

export function useRelativeTime(date?: number): [string | undefined, Dispatch<number>] {
	const [dateState, setDateState] = useState<number | undefined>(date);
	const [timeUntil, setTimeUntil] = useState<string>('...');

	useEffect(() => {
		if (!date && !dateState) return;
		setTimeUntil(getRelativeTime(dateState as number));
		const interval = setInterval(() => setTimeUntil(getRelativeTime(dateState as number)), 5000);
		return () => clearInterval(interval);
	}, [dateState]);

	return [timeUntil, setDateState];
}