import { Dispatch, FC, SetStateAction, useRef } from 'react'
import { Icon, P, Skeleton } from '..'

import { ContentWrapper, TitleWrapper, Wrapper } from './styles'

export interface AccordionProps {
	id: number
	title: string
	selected: boolean
	setSelected: Dispatch<SetStateAction<number>>
}

export const Accordion: FC<AccordionProps> = ({ id, title, children, selected, setSelected }) => {
	const contentRef = useRef<HTMLDivElement>()

	const handleClick = () => {
		if (selected) {
			setSelected(undefined)
		} else {
			setSelected(id)
		}
	}

	return (
		<Wrapper>
			<TitleWrapper selected={selected} onClick={handleClick}>
				<P fontSize={12} letterSpacing={2.4} textTransform={'uppercase'}>
					{title}
				</P>
				<Icon className={'fas fa-angle-down'} />
			</TitleWrapper>
			<ContentWrapper ref={contentRef} selected={selected} textHeight={contentRef?.current?.scrollHeight}>
				{children}
			</ContentWrapper>
		</Wrapper>
	)
}

export const SkeletonAccordion: FC = () => {
	return (
		<Wrapper>
			<TitleWrapper selected={false}>
				<Skeleton height={17} width={250} />
				<Icon className={'fas fa-angle-down'} />
			</TitleWrapper>
			<ContentWrapper selected={false} textHeight={50}/>
		</Wrapper>
	);
}