import { UserProfile } from './types'

export const ranks = {
	owner: 2,
	moderator: 1,
	member: 0,
}

export const sortMembersByRank = (members: UserProfile[]) => {
	return members.sort((a, b) => ranks[b.role] - ranks[a.role])
}
