import { FC, useState, useRef } from 'react';
import { TipWrapper, Tip } from './styles';

interface TooltipProps {
    content: string|JSX.Element;
    position?: 'left'|'right'|'top';
    delay?: number;
    tipMargin?: string;
}

export const Tooltip: FC<TooltipProps> = ({ children, content, position, delay, tipMargin }) => {
    const [showTip, setShowTip] = useState<boolean>();
    const timeoutRef = useRef<number>();

    const handleEnter = () => {
        timeoutRef.current = setTimeout(() => {
            setShowTip(true);
        }, delay || 0);
    }

    const handleLeave = () => {
        clearTimeout(timeoutRef.current);
        setShowTip(false);
    }

    return (
        <TipWrapper onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
            {content && <Tip tipPosition={position} show={showTip} margin={tipMargin}>{content}</Tip>}
            {children}
        </TipWrapper>
    )
}