export function formateTwelveHour(date: Date) {
	let hours = date.getHours();
	let minutes: string | number = date.getMinutes();
	let timeType = hours >= 12 ? 'PM' : 'AM';
	hours %= 12;
	hours = hours ? hours : 12;
	minutes = minutes < 10 ? `0${minutes}` : minutes;
	return `${hours}:${minutes} ${timeType}`;
}

export function getRelativeTime(date: number): string {
	const untilDate = new Date(date);
	const currentDate = new Date();

	const timeBetween = untilDate.getTime() - currentDate.getTime();
	const minutes = Math.floor(timeBetween / 60000);
	const hours = Math.floor(Math.abs(minutes) / 60);
	const days = Math.floor(hours / 24);

	if (Math.abs(minutes) < 60) return `${Math.abs(minutes)} minute${Math.abs(minutes) === 1 ? '' : 's'} ${minutes < 0 ? 'ago' : ''}`;
	if (hours < 24) return `${Math.abs(hours)} hour${Math.abs(hours) === 1 ? '' : 's'} ${minutes < 0 ? 'ago' : ''}`;
	return `${days} day${days === 1 ? '' : 's'} ${minutes < 0 ? 'ago' : ''}`;
}

export function getDuration(seconds: number) {
	const d = Math.floor(seconds / 86400)
    const h = Math.floor(seconds % 86400 / 3600);
    const m = Math.floor(seconds % 3600 / 60);

    return `${d}d, ${h}h, ${m}m`;
}